/*    TÉLÉPHONES  - PORTRAIT   */
@media screen and (max-device-width: 1000px) and (min-device-width: 100px) and (orientation: portrait) { 

  #landing-page {
    display: flex;
    flex-direction: column;
    background-color: rgb(240, 240, 240);
    align-content: flex-start;
    min-width: 0px;
  }
  
  #landing-container {
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  #logo-header {
    height: 45px;
    margin-left: 25px;
    margin-top: 20px;
  }

  #openbtn {
    top: 13px;
    right : 30px;
  }

  
  #landing-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    background-attachment: scroll !important;
  }
  


  #landing-container-overlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.3);
    z-index: 1;
  }


  #landing-text {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    z-index: 5;
    width: 80%;
    padding-top: 100px;
  }
  
  
  #landing-text h1 {
    font-size: 45px;
    margin: 0px;
    margin-bottom: 5px;
    color: white;
    width: 100%;
    font-weight: 300;
  }
  
  
  
  #landing-text p{
    margin-top: 11vh;
    margin-left: 4px;
    font-size: 18px;
    font-weight: 300;
  }

  
  .technology-page {
    padding-top: 0px;
  
  }

  .technology-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 0px;
    padding-left: 0px;
    width: 100%;
    height: fit-content;
  }

  .technology-description {
    width: 80%;
  }

  .technology-content h4{ 
    font-size: 24px;

  }
  
  .technology-content p{ 
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
  }
  
  #platform-img {
    width: 85%;
    height: auto;
    margin-top: 50px;
  }


  .subtitle{
    font-size: 26px;
    font-weight: 300;
    margin: 0px;
    padding-top: 80px;
    padding-bottom: 30px;
    text-align: center;
    width: 90%;
  }

  #customer-page{
    min-height: 300px;
  }
  
  #customer-content{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;

  }

  #image_customer {
    width: 100px;
    height: auto;
    margin: 25px;
  }

  #quote {
    font-size: 20px;
    color: #0026F6;
    font-weight: 400;
    line-height: 25px;
    width: 80%;
    text-align: center;
    margin-top: 120px;
    margin-bottom: 120px;
  }

  #partenaire-content{
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    margin-top: 10px;
  }
  
  #image_partenaire {
    height: 50px;
    width: auto;
    margin: 25px;
  }

  #partenaire-page {
    height: auto;
  }

  #partenaire-content{
    padding-top: 40px;
    padding-bottom: 40px;
  }

  #partenaire-page h4{
    font-size: 30px;
  }

  #logo-hyprview-footer {
    width: 200px;
    height: auto;
    margin-bottom: 30px;
    margin-right: 12px;
  }

  #footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  #footer-content {
    width: 100%;
    min-width: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
  }
  
  #footer-content h2 {
    font-size: 18px;
  }

  #footer-content h4 {
    font-size: 14px;
  }

  #footer-content p {
    font-size: 14px;
  }

  #footer-content a {
    font-size: 14px;
  }
}

