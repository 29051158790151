#landing-page {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 100%;
  min-width: 1000px;
}

#header {
  background-color: rgb(0, 0, 0);
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  position: fixed;
  z-index: 501;
}

#menu {
  transition: opacity 0.4s cubic-bezier(.83,.02,.38,1);
  transition-delay: 250ms;
}

.sidebar {
  display: block;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.90);
  overflow-x: hidden;
  transition: width 0.4s cubic-bezier(.83,.02,.38,1);
  padding-top: 15px;  
  visibility: hidden;  
}

.sidebar a {
  cursor: pointer;
  margin-top: 30px;
  padding: 8px 8px 8px 30px;
  text-decoration: none;
  font-size: 18px;
  font-weight: normal;
  color: rgba(190, 190, 190);
  background-color: rgba(0, 0, 0, 0);
  display: block;
  opacity:100;
}

.sidebar .closebtn {
  position: relative;
  font-size: 35px;
  margin-left: 70%;
  margin-bottom: 50px;
  margin-top: 0px;
  height: 50px;
  color: rgba(190, 190, 190);
  font-weight: bold;
  transition: opacity 0.4s cubic-bezier(.83,.02,.38,1);
  transition-delay: 250ms;
}

.sidebar a:hover {
  color: white;
}
.sidebar .closebtn:hover {
  color: white;
}

#openbtn {
  visibility: hidden;
  position: absolute;
  font-size: 45px;
  height: 45px;
  width: 45px;
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  border: none;
  color: white;
  top: 13px;
}


#navigation-list {
  list-style-type: none;
  margin-right: 60px;
}

#navigation-list li {
  display: inline;
  margin-left: 40px;
}

#logo-header {
  height: 40px;
  margin-left: 30px;
  cursor: pointer;
}

.product-container {
  background-color: black;
  width: 100%;
}

#signin-button {
  border-radius: 0px;
  border: 0px;
  background-color: black;
  color: white;
  border: 1px solid rgb(200, 200, 200);
  width: 70px;
  height: 30px;
  text-align: center;
  font-size: 13px;
  outline:none;
  cursor: pointer;
}

#signin-button:hover {
  border: 1px solid rgb(255, 255, 255);
  background-color: rgb(20, 20, 20);
}

.navigation-button {
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  border: 0px;
  color: white;
  font-size: 13px;
  outline:none;
  text-decoration: underline 1px rgba(255, 255, 255, 0);
  text-underline-offset: 3px;
  transition: text-decoration-color 300ms;
}

.navigation-button:hover {
  text-decoration-color: rgba(255, 255, 255, 1);
}

#landing-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  height: 100vh;
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
}

#landing-container-overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.3);
  z-index: 1;
}

#landing-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 22px;
  z-index: 5;
}


#landing-text h1 {
  font-size: 55px;
  margin: 0px;
  margin-bottom: 5px;
  color: white;
  width: 100%;
  font-weight: 300;
  text-align: center;
}

#landing-text p {
  font-size: 20px;
  color: white;
  font-weight: 250;
  text-align: center;
  margin-bottom: 150px;
}

#landing-text button {
  height: 42px;
  width: auto;
  padding: 10px;
  color: white;
  background-color: rgb(0, 0, 0, 0.3);
  border: 1px solid rgb(200, 200, 200);
  margin-left: 5px;
  font-size: 17px;
}

#landing-text button:hover{
  background-color: rgb(20, 20, 20, 0.5);
  border: 1px solid rgb(255, 255, 255);
  cursor: pointer;
}

.content-separator-landing{    
  background-color: rgb(150, 150, 150);
  height: 1px;
  width: 90%;
  border: 0px;
}

.subtitle{
  font-size: 35px;
  font-weight: 200;
  margin: 0px;
  text-align: center;
}

.technology-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  padding-top: 120px;

}



.technology-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 80px;
  padding-left: 100px;
  height: 550px;
}

.technology-content h4{ 
  font-size: 24px;
}

.technology-content p{ 
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}

.technology-description {
  width: 35%;
}

#platform-img {
  width: 500px;
  height: auto;
}


#customer-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding-top: 40px;
  padding-bottom: 40px;
}

#customer-content{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 70%;
}

#image_customer {
  width: 140px;
  height: auto;
  margin: 25px;
  /* filter: grayscale(100%); */
}

#quote {
  font-size: 28px;
  color: #0026F6;
  font-weight: 400;
  line-height: 40px;
  width: 650px;
  text-align: center;
}

#partenaire-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin-top: 120px;
  height: fit-content;
  margin-bottom: 120px;

}

#partenaire-content{
  width: 80%;
  display: flex;
  justify-content:space-between;
  align-items: center;
  margin-top: 70px;
}

#partenaire-page h1{
  font-size: 40px;
  margin: 0px;
  padding: 0px;
}

.calendly-badge-content {
  border-radius: 1px !important;
  border: solid 1px rgb(255, 255, 255);
}

#image_partenaire {
  height: 80px;
  margin: 25px;
}


#footer-page{
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

#footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 70%;
}

#footer-content {
  margin-left: 80px;
  width: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 70px;
  margin-bottom: 70px;
}

#footer-content h2 {
  font-weight: 300;
  font-size: 18px;
}

#footer-content h4 {
  margin: 0px;
  font-size: 14px;
  margin-bottom: 14px;
  font-weight: 300;
  text-decoration: underline 2px rgba(255, 255, 255, 0);
  text-underline-offset: 3px;
  transition: text-decoration-color 300ms;
}

#footer-content h4:hover {
  text-decoration-color: rgba(240, 240, 240, 1);
}

#footer-content p {
  font-weight: 300;
  margin: 0px;
  margin-bottom: 8px;
  margin-top: 10px;
  font-size: 13px;
  
}

.footer-icon{ 
  color: rgb(240, 240, 240);
  font-size: 40px;
}
#logo-hyprview-footer {
  height: 50px;
}

#lien_mail {
  color: white;
  font-weight: 300;
  text-decoration: none;
  font-size: 13px;

}


/*    BREAKPOINT   */

@media screen and (max-width : 1000px) {

 
  #background-image {
    margin-left: 30vw;
  }


  #service-page {
    padding-bottom: 0px;
  }

  #service-content {
    flex-direction: column;
    align-items: center;
  }

  .service-description {
    margin-bottom: 100px;
  }

  #process-container {
    height:auto;
  }

  .advantages-content {
    flex-direction: column;
  }

  .advantages-description {
    height: 400px;
    justify-content: center;
  }

  #stack-image {
    padding-top: 60px;
  }

  .advantages-content .vl{
    display: none;
  }

  .advantages-content .hl{
    display: block;
    margin-top: 50px;
    width: 350px;
  }

  #process-content {
    flex-direction: column;
  }

 


  #openbtn {
    visibility: visible;
  }
  ul {
    visibility: hidden;
  }

 

@media screen and (max-width : 700px) {

  #landing-text h1{
    font-size: 35px;
  }

  #background-image {
    margin-left: 70vw;
  }

  #landing-container {
    justify-content: center;
  }

  #landing-text {
    margin-left: 0px;
    padding-top:  20vh;
  }

  #landing-text p {
    margin-top: 10vh;
  }

  #contact-content p {
    width: 80%;
    text-align: center;
  }

  #contact-input {
    width: 220px;
  }

  #image_partenaire {
    width: 100px;
    height: auto;
  }


  #logo-hyprview-footer {
    margin-bottom: 40px;
  }

  #footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

  }

  #footer-content {
    width: 100%;
    min-width: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-left: 0px;
  }


}

@media screen and (max-width : 570px) {

  #landing-text h1{
    font-size: 26px;
  }
}
  
}












