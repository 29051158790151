body {
  margin: 0px;
  height: 100% !important;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.3px;
}

body button {
  font-family: 'Roboto', sans-serif;
}

body input {
  font-family: 'Roboto', sans-serif;
}

body textarea {
  font-family: 'Roboto', sans-serif;
}

html {
  margin: 0px;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  width: 100%;
}

/* @font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Light.ttf) format('truetype');
} */

/* @font-face {
  font-family: 'Roboto-regular';
  src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
} */